import { apirc } from '~/configs/apirc';
import urlcat from 'urlcat';
import useSWR from 'swr';
import { errorEmptyArrayFetcher } from '~/modules/screener/containers/useStockScreenerResource';
/** 即時排行 */
export const useDailyRankResource = (options = {}) => {
    const { symbol_root, date, limit, days, volume_gte, volume_lte, amount_gte, amount_lte, order_by, sort_by, } = options;
    const ll = urlcat(`${apirc.newsWall}`, '/api/monitor/daily_rank', {
        symbol_root,
        date,
        limit,
        days,
        volume_gte,
        volume_lte,
        amount_gte,
        amount_lte,
        order_by,
        sort_by,
    });
    const res = useSWR([ll], errorEmptyArrayFetcher, {
        revalidateOnFocus: false,
    });
    return {
        data: res.data,
    };
};
