import { memo, useState } from 'react';
import { NavLink } from '@mantine/core';
import { IconGauge, IconTimeline, IconChartHistogram, IconNotes, IconFlask, IconCpu, } from '@tabler/icons-react';
const sideNavData = [
    {
        label: '看盤',
        icon: IconTimeline,
        children: [
            { label: '經典看盤室' },
            { label: 'VIP看盤室' },
            { label: '選擇權分析' },
            { label: '選擇權報價' },
            { label: '選擇權OI觀察表' },
        ],
    },
    {
        label: '儀表板',
        icon: IconGauge,
        children: [{ label: '市場概覽' }, { label: '漲跌家數' }],
    },
    {
        label: '市場資訊',
        icon: IconChartHistogram,
        defaultOpened: true,
        children: [
            { label: '個股排行' },
            { label: '股期排行' },
            { label: '權值股排行' },
            { label: '熱門個股' },
            { label: '大盤籌碼' },
            { label: '個股篩選' },
            { label: '盤中大單動向' },
            { label: '基本面研究' },
        ],
    },
    {
        label: '交易筆記',
        icon: IconNotes,
        children: [{ label: '海期規劃' }, { label: '交易雜談' }, { label: '系統教學手冊' }],
    },
    {
        label: '統計分析',
        icon: IconFlask,
        children: [{ label: '當日統計' }, { label: '漲跌振幅' }, { label: '走勢軌跡' }],
    },
    {
        label: '程式策略',
        icon: IconCpu,
        children: [{ label: '策略動態' }, { label: '動態回測' }],
    },
];
export const SideNavLink = memo(function SideNavLink() {
    const [activeCategory, setActiveCategory] = useState('');
    return (<div>
      {sideNavData.map(item => {
            const isActive = activeCategory === item.label; // 確認主類別是否被選中
            return (<NavLink key={item.label} label={item.label} icon={<item.icon size='1rem' stroke={1.5}/>} childrenOffset={28} active={isActive} color={'indigo'} // 當前主類別被選中時顯示顏色
             onClick={() => setActiveCategory(isActive ? '' : item.label)} // 點擊時切換活躍狀態
            >
            {item.children.map(child => (<NavLink key={child.label} label={child.label} onClick={() => setActiveCategory(item.label)} // 點擊子類別時設置主類別為活躍
                 style={{ color: activeCategory === item.label ? 'indigo' : undefined }} // 確保子類別也能繼承顏色
                />))}
          </NavLink>);
        })}
    </div>);
});
