import { createMobileCss2 } from '~/css/createMobileCss';
import { css } from '@emotion/react';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '../heineken_template/_private/store';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { StockFuturesRanking } from './stock-futures/StockFuturesRanking';
import { SideNavLink } from './SideNavLink';
import { Industries } from './stock-futures/Industries';
/** 首頁以市場資訊為主 */
/** 首頁以市場資訊為主 */
export const futuresai2_init = {
    global(templateProps) {
        useThemeStore.setState({ theme: 'light' });
        store.charting.setThemeMode('light');
        templateProps.layout.Charting = undefined;
        templateProps.layout.Drawer1 = SideNavLink;
    },
    indexPage(templateProps) {
        templateProps.page.title = '期天大勝';
        templateProps.page.description = '';
        templateProps.page.keywords = '';
        //
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 200px 1fr;
      gap: 4px;

      grid-template-areas:
        'Row1 Row1'
        'Drawer1 Col1'
        'Drawer1 Col1';

      .Chart {
        padding: 4px;
        box-shadow: 0 0 6px 2px #cccccc;
        border-radius: 8px;
      }

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col1 Col1';
      `)}
    `;
        templateProps.layout.Charting = undefined;
        templateProps.layout.Row1 = <div>Top Bar</div>;
        templateProps.layout.Drawer1 = SideNavLink;
        templateProps.layout.Col1 = Industries;
    },
    ['stock-futures/index.page'](templateProps) {
        futuresai2_init.global(templateProps);
        templateProps.page.title = '股期排行';
        templateProps.page.description = '';
        templateProps.page.keywords = '';
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 200px 1fr;
      gap: 4px;

      grid-template-areas:
        'Row1 Row1'
        'Drawer1 Col1'
        'Drawer1 Col1';

      .Chart {
        padding: 4px;
        box-shadow: 0 0 6px 2px #cccccc;
        border-radius: 8px;
      }

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col1 Col1';
      `)}
    `;
        templateProps.layout.Charting = undefined;
        templateProps.layout.Row1 = <div>股期排行</div>;
        templateProps.layout.Drawer1 = SideNavLink;
        templateProps.layout.Col1 = StockFuturesRanking;
    },
};
