import { css } from '@emotion/react';
import { memo, useState } from 'react';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
export const Industries = memo(function Industries() {
    fr_instrument.installData();
    const [selectedCategories, setSelectedCategories] = useState('水泥');
    const allCategories = fr_instrument.getAllIndustries();
    const allIndustry = fr_instrument.getIndustriesBySub(selectedCategories);
    //單一細產業
    const [selectedIndustry, setSelectedIndustry] = useState(selectedCategories + ':' + allIndustry[0]);
    const industrySymbol = fr_instrument.getSymbolsOfIndustry(selectedIndustry);
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    return (<div css={css `
        display: flex;
        gap: 24px;
      `}>
      <div>
        {allCategories.map(s => {
            return (<div key={s} onClick={() => {
                    setSelectedCategories(s);
                }}>
              {s} [{fr_instrument.getIndustriesBySub(s).length}]
            </div>);
        })}
      </div>
      <div>
        {allIndustry.map(s => {
            return (<div key={s} onClick={() => setSelectedIndustry(selectedCategories + ':' + s)}>
              {s}[{fr_instrument.getSymbolsOfIndustry(selectedCategories + ':' + s)?.length}]
            </div>);
        })}
      </div>
      <div>
        {industrySymbol?.map(s => {
            return (<div key={s}>
              {s} {dictionary[s]}
            </div>);
        })}
      </div>
    </div>);
});
