import { css } from '@emotion/react';
import { sortBy } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSignalrStart2_1 } from '~/modules/SDK/Signalr/useSignalrStart2_1';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { useDailyRankResource } from '../units/monitor/useDailyRankResource';
import { stockDecimal } from '~/modules/new-version-counsultant/Quote/stockDecimal';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { KBarWithDailyKBar, KBarWithSignalr } from '../component/Kbar';
import { Button } from '@mantine/core';
const calculateChangePercent = (prevClose, close) => {
    if (prevClose === 0)
        return '0.00'; // 避免除以0的情況
    const changePercent = ((close - prevClose) / prevClose) * 100;
    return changePercent.toFixed(2);
};
const SymbolTableRealTime = memo(function SymbolTableRealTime(props) {
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const symbols = props.symbolQuote;
    return (<div css={css `
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          gap: 4px;
          font-size: 14px;
          border: 1px solid #ccc;
        `}>
        {symbols.map(quote => {
            const amplitudePercentage = (((quote?.high ?? 0) - (quote?.low ?? 0)) / (quote?.prevRef ?? 1)) * 100;
            return (<div key={quote.symbol} css={css `
                display: grid;
                grid-template-columns: 10% 36% 18% 18% 18%;
                width: 100%;
                height: 32px;
              `}>
              <span>
                <KBarWithSignalr quote={quote}/>
              </span>
              <span css={css `
                  align-content: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                `}>
                {fr_instrument.getUnderlying(quote.symbol)?.symbol}
                {dictionary[quote.symbol]}
              </span>
              <span css={css `
                  align-content: center;
                  justify-self: end;
                `}>
                {quote.close.toFixed(stockDecimal(quote.close))}
              </span>
              <span css={css `
                  align-content: center;
                  justify-self: end;
                `}>
                {calculateChangePercent(quote.prevRef, quote.close)}%
              </span>
              <span css={css `
                  align-content: center;
                  justify-self: end;
                `}>
                {quote.volume}
              </span>
            </div>);
        })}
      </div>);
});
const SymbolTableHistory = memo(function SymbolTableHistory(props) {
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const symbols = props.symbolQuote;
    return (<div css={css `
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          gap: 4px;
          font-size: 14px;
          border: 1px solid #ccc;
        `}>
        {symbols.map(quote => {
            const amplitudePercentage = (((quote?.high ?? 0) - (quote?.low ?? 0)) / (quote?.last_close ?? 1)) * 100;
            return (<div key={quote.symbol} css={css `
                display: grid;
                grid-template-columns: 10% 36% 18% 18% 18%;
                width: 100%;
                height: 32px;
              `}>
              <span>
                <KBarWithDailyKBar quote={quote}/>
              </span>
              <span css={css `
                  align-content: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                `}>
                {fr_instrument.getUnderlying(quote.symbol)?.symbol}
                {dictionary[quote.symbol]}
              </span>
              <span css={css `
                  align-content: center;
                  justify-self: end;
                `}>
                {quote.close.toFixed(stockDecimal(quote.close))}
              </span>
              <span css={css `
                  align-content: center;
                  justify-self: end;
                `}>
                {calculateChangePercent(quote.last_close, quote.close)}%
              </span>
              <span css={css `
                  align-content: center;
                  justify-self: end;
                `}>
                {quote.volume}
              </span>
            </div>);
        })}
      </div>);
});
export const StockFuturesRanking = memo(function StockFuturesRanking() {
    useSignalrStart2_1();
    fr_instrument.installData();
    const staticState = useSnapshot(staticStore);
    const stockFutures = staticState.symbol.stockFuturesMapper;
    const stockFuturesSymbol = Object.values(stockFutures ?? []).map(code => `${code}-1`); // 取得英文代碼並自動補 -1
    useEffect(() => {
        signalrStore2.addQuote(stockFuturesSymbol);
        return () => {
            signalrStore2.removeQuote(stockFuturesSymbol);
        };
    }, [JSON.stringify(stockFuturesSymbol)]);
    //
    const futuresQuoteInfos = signalrHooks2.useQuotes(stockFuturesSymbol);
    const changePresent = sortBy(futuresQuoteInfos, datum => ((datum?.close ?? 0) / (datum?.prevRef ?? 0)) * 0.01);
    const volume = sortBy(futuresQuoteInfos, datum => datum?.volume ?? 0);
    const amplitude = sortBy(futuresQuoteInfos, datum => ((datum?.high ?? 0) - (datum?.low ?? 0)) / (datum?.prevRef ?? 1));
    const item = 10;
    const changePresentLong = [...changePresent].reverse().slice(0, item);
    const changePresentShort = changePresent.slice(0, item);
    const volumeSort = volume.reverse().slice(0, item);
    const amplitudeSort = amplitude.reverse().slice(0, item);
    const [historyDate, setHistoryDate] = useState(staticState.tradedDate.days[1].format('YYYY-MM-DD'));
    //歷史資料查詢
    const changePresentLongHistory = useDailyRankResource({
        symbol_root: 'stock_futures',
        date: historyDate,
        limit: 10,
        days: 1,
        sort_by: 'change_percent',
        order_by: 'desc',
    });
    const changePresentShortHistory = useDailyRankResource({
        symbol_root: 'stock_futures',
        date: historyDate,
        limit: 10,
        days: 1,
        sort_by: 'change_percent',
        order_by: 'asc',
    });
    const volumeHistory = useDailyRankResource({
        symbol_root: 'stock_futures',
        date: historyDate,
        limit: 10,
        days: 1,
        sort_by: 'volume',
        order_by: 'desc',
    });
    const amplitudeHistory = useDailyRankResource({
        symbol_root: 'stock_futures',
        date: historyDate,
        limit: 10,
        days: 1,
        sort_by: 'amplitude',
        order_by: 'desc',
    });
    //
    return (<div css={classes.container}>
      <div css={classes.content}>
        <SymbolTableRealTime symbolQuote={changePresentLong}/>
        <SymbolTableRealTime symbolQuote={changePresentShort}/>
        <SymbolTableRealTime symbolQuote={volumeSort}/>
        <SymbolTableRealTime symbolQuote={amplitudeSort}/>
      </div>
      <div>
        {historyDate}
        {staticState.tradedDate.days.slice(1, 6).map((day, index) => (<Button key={index} onClick={() => setHistoryDate(day.format('YYYY-MM-DD'))}>
            {day.format('YYYY-MM-DD')}
          </Button>))}
      </div>
      <div css={classes.content}>
        <SymbolTableHistory symbolQuote={changePresentLongHistory?.data ?? []}/>
        <SymbolTableHistory symbolQuote={changePresentShortHistory?.data ?? []}/>
        <SymbolTableHistory symbolQuote={volumeHistory?.data ?? []}/>
        <SymbolTableHistory symbolQuote={amplitudeHistory?.data ?? []}/>
      </div>
    </div>);
});
const classes = {
    container: css `
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 8px;
  `,
    content: css `
    display: flex;
    width: 100%;
    gap: 4px;
  `,
};
