import { css } from '@emotion/react';
import { memo } from 'react';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
export const KBarWithSignalr = memo(function KBarWithSignalr(props_) {
    const value = props_.quote;
    const limitUp = value?.limitUpPrice ?? 0;
    const limitDn = value?.limitDownPrice ?? 0;
    const close = value?.close ?? 0;
    const open = value?.open ?? 0;
    const high = value?.high ?? 0;
    const low = value?.low ?? 0;
    const prevRef = value?.prevRef ?? 0;
    const base1 = close > open ? close : open;
    const base2 = close <= open ? close : open;
    const range = limitUp - limitDn;
    const bar = Math.abs(close - open);
    const lineup = high - base1;
    const linedn = base2 - low;
    const barHigh = bar / range === 0 ? 0.05 : bar / range;
    const borderUp = lineup / range;
    const borderDn = linedn / range;
    const barColor = close > prevRef ? '#ff3232' : close < prevRef ? '#0dc316' : '#cccc22';
    return (<div css={css `
          ${fill_vertical_all_center};
        `}>
        <div css={css `
            width: 1.4px;
            height: ${borderUp * 100}%;
            background-color: #737375;
          `}/>
        <div css={css `
            width: 8px;
            height: ${barHigh * 100}%;
            background-color: ${barColor};
            border-radius: 0.4px;
          `}/>
        <div css={css `
            width: 1.4px;
            height: ${borderDn * 100}%;
            background-color: #737375;
          `}/>
      </div>);
});
export const KBarWithDailyKBar = memo(function KBarWithDailyKBar(props_) {
    const value = props_.quote;
    const limitUp = value?.last_close * 1.1 ?? 0;
    const limitDn = value?.last_close * 0.9 ?? 0;
    const close = value?.close ?? 0;
    const open = value?.open ?? 0;
    const high = value?.high ?? 0;
    const low = value?.low ?? 0;
    const prevRef = value?.last_close ?? 0;
    const base1 = close > open ? close : open;
    const base2 = close <= open ? close : open;
    const range = limitUp - limitDn;
    const bar = Math.abs(close - open);
    const lineup = high - base1;
    const linedn = base2 - low;
    const barHigh = bar / range === 0 ? 0.05 : bar / range;
    const borderUp = lineup / range;
    const borderDn = linedn / range;
    const barColor = close > prevRef ? '#ff3232' : close < prevRef ? '#0dc316' : '#cccc22';
    return (<div css={css `
          ${fill_vertical_all_center};
        `}>
        <div css={css `
            width: 1.4px;
            height: ${borderUp * 100}%;
            background-color: #737375;
          `}/>
        <div css={css `
            width: 8px;
            height: ${barHigh * 100}%;
            background-color: ${barColor};
            border-radius: 0.4px;
          `}/>
        <div css={css `
            width: 1.4px;
            height: ${borderDn * 100}%;
            background-color: #737375;
          `}/>
      </div>);
});
